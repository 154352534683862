export const environment = {
  production: false,
  localStoragePrefix: "rhsc",
  permissionPrefix: "rhsc_p",
  keycloak: {
    url: "https://superc-qa.gruporihappy.com.br/",
  },
  apiCadastro: {
    base: "https://superc-qa.gruporihappy.com.br/",
    baselocal:"https://superc-qa.gruporihappy.com.br/cadastro/",
    register: "admin/Register",
    lgpd: "admin/Lgpd",
    admin: "admin/Admin",
    auth:"auth",
    consulta:"consulta"
  },
  atributos: {
    pbkids: "0FE0EF1F-F8D8-49DA-8B55-637C016E1B97",
    rihappy: "13FACA7D-BA22-441F-A525-2B39EAEE9316"
  },
  samlProviderUrl :"https://superc-qa.gruporihappy.com.br/auth/saml/login",
  samlCallback:"https://superc-qa.gruporihappy.com.br/crm-login",
  loginPage:"/login",
  origem: "aws"
};
