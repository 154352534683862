import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IDadosOptout } from "src/app/shared/interfaces/dados-optout.interface";

@Injectable({
  providedIn: "root",
})
export class DescadastreSeService {
  private url = `https://superc.gruporihappy.com.br/api/IntegracaoEmarsys/Contato/OptoutComunicacao`;

  constructor(private http: HttpClient) {}

  salvar(dadosOptout: IDadosOptout): Observable<any> {
    return this.http.post<IDadosOptout>(this.url, dadosOptout);
  }
}
