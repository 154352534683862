import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { IDadosOptout } from "src/app/shared/interfaces/dados-optout.interface";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-descadastre-se",
  templateUrl: "./descadastre-se.component.html",
  styleUrls: ["./descadastre-se.component.scss"],
})
export class DescadastreSeComponent implements OnInit {
  dadosOptout: IDadosOptout = {
    Empresa: 1,
    Uid: "",
    Cid: "",
    Llid: "",
    MotivoOptout: "",
    MotivoOutros: "",
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const valorRota = params["valorRota"];
      this.route.queryParamMap.subscribe((params) => {
        const parValue = params.get("par");
        if (parValue) {
          const splitValues = parValue.split("_");
          const value1 = splitValues[0];
          const value2 = splitValues[1];
          const value3 = splitValues[2];

          this.dadosOptout = {
            Empresa: parseInt(valorRota),
            Uid: value1,
            Cid: value2,
            Llid: value3,
            MotivoOptout: this.dadosOptout.MotivoOptout,
            MotivoOutros: "",
          };
        }
      });
    });
  }
}
