import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as moment from 'moment';

import { EmpresaService } from "src/app/adm/services/empresa.service";
import { SistemaOrigemService } from "src/app/adm/services/sistema-origem.service";
import { TipoEmailService } from "src/app/adm/services/tipo-email.service";
import { TipoEnderecoService } from "src/app/adm/services/tipo-endereco.service";
import { TipoTelefoneService } from "src/app/adm/services/tipo-telefone.service";
import { CepService } from "src/app/adm/services/cep.service";
import { ClienteService } from "src/app/adm/services/cliente.service";
import { Cliente } from "src/app/shared/interfaces/cliente.interface";
import { Empresa } from "src/app/shared/interfaces/empresa.interface";
import { SistemaOrigem, SistemaOrigemKey } from "src/app/shared/interfaces/sistema-origem.interface";
import { TipoEmail } from "src/app/shared/interfaces/tipo-email.interface";
import { TipoEndereco } from "src/app/shared/interfaces/tipo-endereco.interface";
import { TipoTelefone } from "src/app/shared/interfaces/tipo-telefone.interface";
import { LoadingService } from "src/app/shared/services/loading.service";

@Component({
  selector: "app-add-cliente",
  templateUrl: "./add-cliente.component.html",
  styleUrls: ["./add-cliente.component.scss"],
})
export class AddClienteComponent implements OnInit {
  cliente!: Cliente;
  empresas: Empresa[] = [];
  sistemasOrigem: SistemaOrigem[] = [];
  apikeyList: SistemaOrigemKey[] = [];
  apikeySelected: string = "";
  tiposEndereco: TipoEndereco[] = [];
  tiposEmail: TipoEmail[] = [];
  tiposTelefone: TipoTelefone[] = [];

  generosList = [
    "Prefiro não informar",
    "Feminino",
    "Masculino"
  ]

  tiposDocumentoList = [
    "CPF",
    "CNPJ"
  ];

  tiposClienteList = [
    "Pessoa Física",
    "Pessoa Jurídica"
  ];

  addClienteForm!: FormGroup;

  loading = false;

  constructor(
    private clienteService: ClienteService,
    private empresaService: EmpresaService,
    private sistemaOrigemService: SistemaOrigemService,
    private tipoEnderecoService: TipoEnderecoService,
    private tipoEmailService: TipoEmailService,
    private tipoTelefoneService: TipoTelefoneService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private toastService: ToastrService,
    private cepService: CepService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.cliente = {} as Cliente;
    const id = this.route.snapshot.paramMap.get('id');

    this.createForm();
    this.listarEmpresas();
    this.listarSistemasOrigem();
    this.listarApiKey();
    this.listarTiposEndereco();
    this.listarTiposEmail();
    this.listarTiposTelefone();

    if (id != null) {
      this.loadingService.setLoading(true);
      this.clienteService.dadosCliente(id).subscribe(
        (res) => {
          this.cliente = res.Data;

          this.addClienteForm.controls['Genero'].setValue(this.cliente.Genero);
          this.addClienteForm.controls['TipoDocumento'].setValue(this.cliente.TipoDocumento);
          this.addClienteForm.controls['TipoCliente'].setValue(this.cliente.TipoCliente);


          if(this.cliente.DataNascimento != undefined && this.isYearGreaterThanOne(this.cliente.DataNascimento))
            this.cliente.DataNascimentoFormatado = (moment(this.cliente.DataNascimento)).format('DD/MM/YYYY');

          this.cliente.DataCriacaoFormatado = (moment(this.cliente.DataCriacao)).format('DD/MM/YYYY HH:mm:ss');
          this.cliente.DataAtualizacaoFormatado = (moment(this.cliente.DataAtualizacao)).format('DD/MM/YYYY HH:mm:ss');

          if (this.cliente.Empresa)
            this.addClienteForm.controls['Bandeira'].setValue(this.cliente.Empresa.Nome);

          if (this.cliente.ClienteOrigem.length) {
            this.cliente.ClienteOrigem.forEach(f => f.DataCriacaoFormatted = new Date(f.DataCriacao));

            var origemOrdenada = this.cliente.ClienteOrigem.sort((n1, n2) => {
              if (n1 > n2) {
                return 1;
              }
              if (n1 < n2) {
                return -1;
              }
              return 0;
            });

            this.addClienteForm.controls['SistemaOrigem'].setValue(origemOrdenada[0]?.SistemaOrigem.Nome);
          }
          if (this.cliente.Atributos?.length > 0) {
            this.removeAtributoForm(0);

            this.cliente.Atributos.forEach(f => {
              if(f.Atributo?.Nome == "Comunicacao - Email" || f.Atributo?.Nome == "Comunicacao - Whatsapp" || f.Atributo?.Nome == "Comunicacao - SMS"){
              const atributoForm = new FormGroup({
                Nome: new FormControl(f.Atributo.Nome),
                Valor: f.Valor == "False" ? new FormControl(false) : new FormControl(true)
              });

              this.atributosForm.push(atributoForm);
            }});
          }else{
            this.atributosForm.clear();
          }
          
          if (this.cliente.Enderecos?.length > 0) {
            this.removeEnderecoForm(0);

            this.cliente.Enderecos.forEach(f => {
              const enderecoForm = new FormGroup({
                Rua: new FormControl(f.Rua),
                Numero: new FormControl(f.Numero),
                Bairro: new FormControl(f.Bairro),
                Cep: new FormControl(f.Cep),
                Cidade: new FormControl(f.Cidade),
                Estado: new FormControl(f.Estado),
                Complemento: new FormControl(f.Complemento),
                TipoEndereco: new FormControl(f.TipoEndereco?.Tipo),
                SistemaOrigemId: new FormControl(f.SistemaOrigemId),
              });

              this.enderecosForm.push(enderecoForm);
            });
          } else {
            this.removeEnderecoForm(0);
          }

          if (this.cliente.Emails?.length > 0) {
            this.removeEmailForm(0);

            this.cliente.Emails.forEach(f => {
              const emailForm = new FormGroup({
                Endereco: new FormControl(f.Endereco),
                Tipo: new FormControl(f.TipoEmail?.Tipo),
                SistemaOrigemId: new FormControl(f.SistemaOrigemId),
                Id: new FormControl(f.Id)
              });

              this.emailsForm.push(emailForm);
            });
          } else {
            this.removeEmailForm(0);
          }

          if (this.cliente.Telefones?.length > 0) {
            this.removeTelefoneForm(0);

            this.cliente.Telefones.forEach(f => {
              const telefoneForm = new FormGroup({
                DDI: new FormControl(f.DDI),
                DDD: new FormControl(f.DDD),
                Numero: new FormControl(f.Numero),
                Tipo: new FormControl(f.TipoTelefone?.Tipo),
                SistemaOrigemId: new FormControl(f.SistemaOrigemId),
                Id: new FormControl(f.Id)
              });

              this.telefonesForm.push(telefoneForm);
            });
          } else {
            this.removeTelefoneForm(0);
          }

          if (this.cliente.Criancas?.length > 0) {
            this.removeCriancaForm(0);

            this.cliente.Criancas.forEach(f => {
              const criancaForm = new FormGroup({
                Id: new FormControl(f.Id),
                Nome: new FormControl(f.Nome),
                MesNascimento: new FormControl(Number(f.MesNascimento)),
                AnoNascimento: new FormControl(Number(f.AnoNascimento)),
                SistemaOrigemId: new FormControl(f.SistemaOrigemId),
              });

              this.criancasForm.push(criancaForm);
            });
          } else {
            this.removeCriancaForm(0);
          }

          this.addClienteForm.markAsPristine();
          this.addClienteForm.get("Enderecos")?.markAsPristine();
          this.addClienteForm.get("Emails")?.markAsPristine();
          this.addClienteForm.get("Telefones")?.markAsPristine();
          this.addClienteForm.get("Criancas")?.markAsPristine();

          this.loading = false;
          this.loadingService.setLoading(false);
        },
        (err) => {
          this.loadingService.setLoading(false);
          this.loading = false;
          console.error(err)
        }
      );
    }
  }

  createForm() {
    this.addClienteForm = new FormGroup({
      Nome: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      Sobrenome: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      DataNascimento: new FormControl(""),
      TipoCliente: new FormControl("", [Validators.required]),
      Documento: new FormControl("", [Validators.required]),
      TipoDocumento: new FormControl("", [Validators.required]),
      Genero: new FormControl(""),
      DataRegistro: new FormControl(""),
      DataAtualizacao: new FormControl(""),
      Bandeira: new FormControl("", [Validators.required]),
      NumeroLoja: new FormControl(""),
      Atributos: new  FormArray([this.createAtributoForm()]),
      SistemaOrigem: new FormControl("", [Validators.required]),
      Enderecos: new FormArray([this.createEnderecoForm()]),
      Emails: new FormArray([this.createEmailForm()]),
      Telefones: new FormArray([this.createTelefoneForm()]),
      Criancas: new FormArray([this.createCriancaForm()]),
    });
  }

  get enderecosForm(): FormArray {
    return this.addClienteForm.get("Enderecos") as FormArray;
  }
  get atributosForm(): FormArray {
    return this.addClienteForm.get("Atributos") as FormArray;
  }
  get emailsForm(): FormArray {
    return this.addClienteForm.get("Emails") as FormArray;
  }

  get telefonesForm(): FormArray {
    return this.addClienteForm.get("Telefones") as FormArray;
  }

  get criancasForm(): FormArray {
    return this.addClienteForm.get("Criancas") as FormArray;
  }

  addEnderecoForm() {
    this.enderecosForm.push(this.createEnderecoForm());
  }
  addEmailForm() {
    this.emailsForm.push(this.createEmailForm());
  }

  addTelefoneForm() {
    this.telefonesForm.push(this.createTelefoneForm());
  }

  addCriancaForm() {
    this.criancasForm.push(this.createCriancaForm());
  }

  createEnderecoForm(): FormGroup {
    return new FormGroup({
      Rua: new FormControl(""),
      Numero: new FormControl(""),
      Bairro: new FormControl(""),
      Cep: new FormControl(""),
      Cidade: new FormControl(""),
      Estado: new FormControl(""),
      Complemento: new FormControl(""),
      TipoEndereco: new FormControl(""),
      SistemaOrigemId: new FormControl(""),
    });
  }
  createAtributoForm(): FormGroup {
    return new FormGroup({
      Nome: new FormControl(""),
      Valor: new FormControl("")
    });
  }
  createEmailForm(): FormGroup {
    return new FormGroup({
      Endereco: new FormControl(""),
      Tipo: new FormControl("Pessoal"),
      SistemaOrigemId: new FormControl(""),
    });
  }

  createTelefoneForm(): FormGroup {
    return new FormGroup({
      DDI: new FormControl(""),
      DDD: new FormControl(""),
      Numero: new FormControl(""),
      Tipo: new FormControl("Celular"),
      SistemaOrigemId: new FormControl(""),
    });
  }

  createCriancaForm(): FormGroup {
    return new FormGroup({
      Id: new FormControl(null),
      Nome: new FormControl(""),
      MesNascimento: new FormControl(Number),
      AnoNascimento: new FormControl(Number),
      SistemaOrigemId: new FormControl(""),
    });
  }
  removeAtributoForm(index: number) {
    this.atributosForm.removeAt(index);

  }
  removeEnderecoForm(index: number) {
    this.enderecosForm.removeAt(index);
    this.enderecosForm.markAsDirty();
  }

  removeEmailForm(index: number) {
    this.emailsForm.removeAt(index);
    this.emailsForm.markAsDirty();
  }

  removeTelefoneForm(index: number) {
    this.telefonesForm.removeAt(index);
    this.telefonesForm.markAsDirty();
  }

  removeCriancaForm(index: number) {
    this.criancasForm.removeAt(index);
    this.criancasForm.markAsDirty();
  }

  addDocumento() {
    console.log("addDocumento");
  }

  isDadosPessoaisDirty(): boolean {
    if (this.addClienteForm.get("Nome")?.dirty)
      return true;

    if (this.addClienteForm.get("Sobrenome")?.dirty)
      return true;

    if (this.addClienteForm.get("DataNascimento")?.dirty)
      return true;

    if (this.addClienteForm.get("TipoCliente")?.dirty)
      return true;

    if (this.addClienteForm.get("Documento")?.dirty)
      return true;

    if (this.addClienteForm.get("TipoDocumento")?.dirty)
      return true;

    if (this.addClienteForm.get("Genero")?.dirty)
      return true;

    if (this.addClienteForm.get("Bandeira")?.dirty)
      return true;

    if (this.addClienteForm.get("NumeroLoja")?.dirty)
      return true;

    return false;
  }

  salvar() {
    this.loadingService.setLoading(true);

    if (this.addClienteForm.valid) {
      const cliente = this.addClienteForm.getRawValue() as Cliente;

      // selecionando a apikey para envio no cadastro
      if (cliente.SistemaOrigem && cliente.SistemaOrigem.length) {
        this.apikeyList.map(apiKey => {
          if (apiKey.Nome == cliente.SistemaOrigem) {
            this.apikeySelected = apiKey.Key;
          }
        })
      }

      cliente.Id = this.cliente.Id != null ? this.cliente.Id : undefined;

      if (!this.addClienteForm.get("Enderecos")?.dirty)
        cliente.Enderecos = [];

      if (!this.addClienteForm.get("Emails")?.dirty)
        cliente.Emails = [];

      if (!this.addClienteForm.get("Telefones")?.dirty)
        cliente.Telefones = [];

      if (!this.addClienteForm.get("Criancas")?.dirty)
        cliente.Criancas = [];

      if (!cliente.Id || cliente.Id == undefined) { // cadastro de cliente
        this.clienteService.salvar(cliente, this.apikeySelected).subscribe(
          (res) => {
            this.loadingService.setLoading(false);

            if (!cliente.Id || cliente.Id == undefined) {
              this.toastService.success("Cliente cadastrado com sucesso!");
            } else {
              this.toastService.success("Cliente atualizado com sucesso!");
            }

            setTimeout(() => {
              this.router.navigateByUrl('/adm/clientes');
            }, 2000);
          },
          (err) => {
            this.loadingService.setLoading(false);
            if (!cliente.Id || cliente.Id == undefined) {
              this.toastService.error(err.error.Message, "Erro ao realizar o cadastro do cliente.");
            }
          });
      } else { // alteração de cliente 
        if (this.isDadosPessoaisDirty())
          this.alterarDadosPessoais(cliente);

        else if (this.addClienteForm.get("Enderecos")?.dirty)
          this.alterarEnderecos(cliente);

        else if (this.addClienteForm.get("Emails")?.dirty)
          this.alterarEmails(cliente);

        else if (this.addClienteForm.get("Telefones")?.dirty)
          this.alterarTelefones(cliente);

         else if (this.addClienteForm.get("Criancas")?.dirty)
          this.alterarCriancas(cliente);

          else if (this.atributosForm?.touched)
          this.alterarAtributos(cliente);
      }
    } else {
      this.loadingService.setLoading(false);
      this.addClienteForm.markAllAsTouched();
      this.toastService.error(
        "Para prosseguir, preencha os campos obrigatórios!"
      );
    }
  }
   isYearGreaterThanOne(dateString: string): boolean {
    const date = new Date(dateString);
    const minimumDate = new Date(1, 0, 1);
  
    return date.getFullYear() > 1;
  }
  alterarDadosPessoais(cliente: Cliente) {
    this.loadingService.setLoading(true);

    this.clienteService.alterarDadosPessoais(cliente, this.apikeySelected).subscribe(
      (res) => {
        // this.loadingService.setLoading(true);

        if (this.addClienteForm.get("Enderecos")?.dirty)
          this.alterarEnderecos(cliente);

        else if (this.addClienteForm.get("Emails")?.dirty)
          this.alterarEmails(cliente);

        else if (this.addClienteForm.get("Telefones")?.dirty)
          this.alterarTelefones(cliente);

        else if (this.addClienteForm.get("Criancas")?.dirty)
          this.alterarCriancas(cliente);
      
        else if (this.addClienteForm.get("Criancas")?.dirty)
          this.alterarCriancas(cliente);

          else if (this.atributosForm?.touched)
          this.alterarAtributos(cliente);
        else
          this.router.navigateByUrl('/adm/clientes');
      },
      (err) => {
        this.loadingService.setLoading(false);
        this.toastService.error(err.error.Message, "Erro ao atualizar os dados pessoais do cliente.");
      });
  }

  alterarEnderecos(cliente: Cliente) {

    this.loadingService.setLoading(true);

    this.clienteService.alterarEnderecos(cliente.Enderecos, this.apikeySelected, cliente.Id as string).subscribe(
      (res) => {
        // this.loadingService.setLoading(true);

        if (this.addClienteForm.get("Emails")?.dirty)
          this.alterarEmails(cliente);

        else if (this.addClienteForm.get("Telefones")?.dirty)
          this.alterarTelefones(cliente);

        else if (this.addClienteForm.get("Criancas")?.dirty)
          this.alterarCriancas(cliente);
          
       else if (this.atributosForm?.touched)
          this.alterarAtributos(cliente);
        else
          this.router.navigateByUrl('/adm/clientes');
      },
      (err) => {
        this.loadingService.setLoading(false);
        this.toastService.error(err.error.Message, "Erro ao atualizar os endereços do cliente.");
      });
  }

  alterarEmails(cliente: Cliente) {
    this.loadingService.setLoading(true);
    this.clienteService.alterarEmails(cliente.Emails, this.apikeySelected, cliente.Id as string).subscribe(
      (res) => {
        // this.loadingService.setLoading(true);

        if (this.addClienteForm.get("Telefones")?.dirty)
          this.alterarTelefones(cliente);

        else if (this.addClienteForm.get("Criancas")?.dirty)
          this.alterarCriancas(cliente);

        else if (this.atributosForm?.touched)
          this.alterarAtributos(cliente);
        else
          this.router.navigateByUrl('/adm/clientes');
      },
      (err) => {
        this.loadingService.setLoading(false);
        this.toastService.error(err.error.Message, "Erro ao atualizar os emails do cliente.");
      });
  }
  trocarComunicacao() {
   this.atributosForm.markAsTouched();
  }

  onCepBlur(index: number) {
    const cep = this.enderecosForm.at(index).get("Cep")?.value;
    if (cep.length === 8) {
      this.loadingService.setLoading(true);
      this.cepService.buscarEnderecoPorCep(cep, this.apikeySelected).subscribe(
        (res) => {

          this.enderecosForm.at(index).get('Rua')?.setValue(res.Logradouro);
          this.enderecosForm.at(index).get('Bairro')?.setValue(res.Bairro);
          this.enderecosForm.at(index).get('Estado')?.setValue(res.Uf);
          this.enderecosForm.at(index).get('Cidade')?.setValue(res.Localidade);
          this.loadingService.setLoading(false);
          this.enderecosForm.at(index).get('Cep')?.setErrors(null);

        },
        (err) => {
          this.loadingService.setLoading(false);
          this.toastService.error(err.error.Message, "Cep Não Encontrado");
          this.enderecosForm.at(index).get('Rua')?.setValue("");
          this.enderecosForm.at(index).get('Bairro')?.setValue("");
          this.enderecosForm.at(index).get('Estado')?.setValue("");
          this.enderecosForm.at(index).get('Cidade')?.setValue("");
          this.enderecosForm.at(index).get('Cep')?.setErrors({ invalid: true });
        });
    }else{
      this.toastService.error("CEP precisa ter oito dígitos");

    }
  }

  retornaAtributoLength() {
    return this.atributosForm.length;
   }
  alterarTelefones(cliente: Cliente) {
    this.loadingService.setLoading(true);

    this.clienteService.alterarTelefones(cliente.Telefones, this.apikeySelected, cliente.Id as string).subscribe(
      (res) => {
        // this.loadingService.setLoading(true);

        if (this.addClienteForm.get("Criancas")?.dirty)
          this.alterarCriancas(cliente);

        else if (this.atributosForm?.touched)
          this.alterarAtributos(cliente);

        else
          this.router.navigateByUrl('/adm/clientes');

      },
      (err) => {
        this.loadingService.setLoading(false);
        this.toastService.error(err.error.Message, "Erro ao atualizar os telefones do cliente.");
      });
  }

  alterarCriancas(cliente: Cliente) {
    this.loadingService.setLoading(true);

    this.clienteService.alterarCriancas(cliente.Criancas, this.apikeySelected, cliente.Id as string).subscribe(
      (res) => {
       if (this.atributosForm?.touched)
        this.alterarAtributos(cliente);
        // this.loadingService.setLoading(true);
        this.router.navigateByUrl('/adm/clientes');

        // setTimeout(() => {
        //   this.router.navigateByUrl('/adm/clientes');
        // }, 2000);
      },
      (err) => {
        this.loadingService.setLoading(false);
        this.toastService.error(err.error.Message, "Erro ao atualizar os crianças do cliente.");
      });
  } 
   alterarAtributos(cliente: Cliente) {
    this.loadingService.setLoading(true);

    this.clienteService.alterarAtributos(this.atributosForm.value, this.apikeySelected, cliente.Id as string).subscribe(
      (res) => {
        // this.loadingService.setLoading(true);
        this.router.navigateByUrl('/adm/clientes');

        // setTimeout(() => {
        //   this.router.navigateByUrl('/adm/clientes');
        // }, 2000);
      },
      (err) => {
        this.loadingService.setLoading(false);
        this.toastService.error(err.error.Message, "Erro ao atualizar os Atributos do cliente.");
      });
  }

  listarEmpresas() {
    this.loading = true;
    this.empresaService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.empresas = res.Data;
      },
      (err) => console.error(err)
    );
  }

  listarSistemasOrigem() {
    this.loading = true;
    this.sistemaOrigemService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.sistemasOrigem = res.Data;
      },
      (err) => console.error(err)
    );
  }

  listarTiposEndereco() {
    this.loading = true;
    this.tipoEnderecoService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.tiposEndereco = res.Data;
      },
      (err) => console.error(err)
    );
  }

  listarTiposEmail() {
    this.loading = true;
    this.tipoEmailService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.tiposEmail = res.Data;
      },
      (err) => console.error(err)
    );
  }

  listarTiposTelefone() {
    this.loading = true;
    this.tipoTelefoneService.listarTodos().subscribe(
      (res) => {
        this.loading = false;
        this.tiposTelefone = res.Data;
      },
      (err) => console.error(err)
    );
  }

  listarApiKey() {
    this.loading = true;
    this.sistemaOrigemService.listarApiKey().subscribe(
      (res) => {
        this.loading = false;
        this.apikeyList = res.Data;
      },
      (err) => console.error(err)
    );
  }

  onChangeTipoCliente(tipoDocumentoSelecionado: string) {
    if (tipoDocumentoSelecionado == "CPF")
      this.addClienteForm.controls['TipoCliente'].setValue("Pessoa Física");
    else
      this.addClienteForm.controls['TipoCliente'].setValue("Pessoa Jurídica");
  }
}
